<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="课程名称：">
					<el-autocomplete
						v-model.trim="formInline.name"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
						@select="handleSelect"
					></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeStatus(scope.row)"> </el-switch>
				</template>
				<template #file="{ scope }">
					<el-button type="text" size="mini" @click="uploadMaterial(scope.row)"
						>上传材料（{{ (scope.row.file && scope.row.file.length) || 0 }}）</el-button
					>
				</template>

				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView ref="createView" :isShowDetail="isShowDetail" @getList="getList" />
	</div>
</template>

<script>
export default {
	name: 'subject',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			formInline: {
				name: '',
			},
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '课程编码',
					prop: 'courseCode',
					formatter: (row) => {
						return row.courseCode || '-';
					},
				},
				{
					label: '课程名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '课程类型',
					prop: 'contentTypeDictionaryItem',
					formatter: (row) => {
						return row.contentTypeDictionaryItem?.title || '-';
					},
				},
				{
					label: '所属科目',
					prop: 'trainingSubject',
					formatter: (row) => {
						return row.trainingSubject?.title || '-';
					},
				},
				{
					label: '材料附件',
					prop: 'file',
					width: 130,
				},
				{
					label: '课程定价',
					prop: 'price',
					formatter: (row) => {
						return row.price / 100 + '元' || '-';
					},
				},

				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			isShowDetail: false,
			options: [],
			filterLoading: false,
		};
	},
	props: ['tableType', 'tableHeadTown', 'formData'],
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		handleSelect(subInput) {
			this.formInline.name = subInput.name;
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		changeStatus(row) {
			this.$http
				.patch(this.api.getCourses + '/' + row.id + '.json', { enabled: row.enabled })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get(this.api.getCourses + '.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							res.data.collection.forEach((item) => {
								item.file = [];
								if (item.attachmentMap['DOCUMENT'] && !item.attachmentMap['VIDEO']) {
									item.file = [...item.attachmentMap['DOCUMENT']];
								} else if (item.attachmentMap['VIDEO'] && !item.attachmentMap['DOCUMENT']) {
									item.file = [...item.attachmentMap['VIDEO']];
								} else if (!item.attachmentMap['DOCUMENT'] && !item.attachmentMap['VIDEO']) {
									item.file = [];
								} else {
									item.file = [...item.attachmentMap['DOCUMENT'], ...item.attachmentMap['VIDEO']];
								}
							});
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			this.$refs.createView.init(row);
			this.isShowDetail = isShowDetail;
		},
		uploadMaterial(row) {
			this.$router.push({
				path: '/course/textbook/uploadMaterial',
				query: {
					id: row.id,
					name: row.name,
					trainingSubject: row.trainingSubject?.title,
					courseCode: row.courseCode,
					price: row.price,
					trainingSubjectId: row.trainingSubject?.id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.upload-file {
	color: var(--prev-color-primary);
	cursor: pointer;
}
</style>
